import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "ShowQue",
    component: () => import("../views/ShowQue.vue"),
  },
  {
    path: "/OnlineQue",
    name: "OnlineQue",
    component: () => import("../views/OnlineQue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
