import Axios from "axios";

const actions = {
  ///////////////////////////////////////////////////  QueTable  /////////////////////////////////////////////////////////////
  async actQueGet(store, data) {
    return await Axios.post(
      `${process.env.VUE_APP_API_URL}/queTable/getQueOnline`,
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },  
  ///////////////////////////////////////////////////  QueTable  /////////////////////////////////////////////////////////////
};

export default actions;
